<template>
  <div v-for="post in posts" :key="post.id">
    <PostTemplate v-if="post" :post="post" />
  </div>
</template>

<script>
import PostTemplate from "./PostTemplate.vue";

export default {
  components: { PostTemplate },
  data() {
    return {
      posts: [],
    };
  },
  created() {
    this.getPosts();
  },
  methods: {
    getPosts() {
      let params = { dataType: "list", isClientMural: true };
      this.$root
        .apiGet(this.$root.api.posts, { params })
        .then((response) => {
          this.posts = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>
